import Image from 'next/legacy/image'
import styled from 'styled-components'
import { useState, useEffect, FC } from 'react'
import { ButtonDS } from '../../'
import { BannerIH } from '../../../interfaces'

import { tokens } from '@JOTAJornalismo/jota-design-system'
const { colors, fonts } = tokens

const BannerPROComponent: FC<{ bannerPRO: BannerIH }> = ({
    bannerPRO
}): React.JSX.Element => {
    const [windowWidth, setWindowWidth] = useState(1440)

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            handleResize()
            window.addEventListener('resize', () => handleResize())
        }
    }, [])

    // /**
    //  * Retorna a URL da imagem do banner com base na largura da tela.
    //  * @return {string} A URL da imagem do banner.
    //  */
    // function changeImageSrc() {
    //     if (windowWidth <= 499) {
    //         return proBanner?.bannerPro?.JOTA_BANNERPRO_imagemobile
    //             ?.mediaItemUrl
    //     } else if (windowWidth <= 1200) {
    //         return proBanner?.bannerPro?.JOTA_BANNERPRO_imagetablet
    //             ?.mediaItemUrl
    //     } else {
    //         return proBanner?.bannerPro?.JOTA_BANNERPRO_imagedesktop
    //             ?.mediaItemUrl
    //     }
    // }

    // /**
    //  * Retorna um redirect para o link selecionado pelo painel admin.
    //  * @return {redirect} p/ a URL selecionada no admin.
    //  */
    // function handleButtonClick() {
    //     return window.open(
    //         proBanner?.bannerPro?.JOTA_BUTTONBANNERPRO_link.url,
    //         '_blank',
    //         'noopener,noreferrer'
    //     )
    // }

    return (
        <Container>
            <LeftContainer>
                <BannerImage
                    style={{ maxHeight: '342px' }}
                    src={
                        windowWidth <= 1199
                            ? bannerPRO.images.mobile.url
                            : bannerPRO.images.desktop.url
                    }
                    alt='banner PRO'
                />
            </LeftContainer>

            <RightContainer>
                <Image
                    src={'/images/PRO.svg'}
                    width={190}
                    height={40}
                    style={{ cursor: 'pointer' }}
                    alt='Banner JOTA PRO'
                />

                <Title>{bannerPRO?.text}</Title>
                <ButtonDS
                    label='SAIBA MAIS'
                    size='medium'
                    color='white'
                    type='filled'
                    width={windowWidth <= 499 ? '100%' : 'auto'}
                    onClick={() => window.open(bannerPRO.url, '_blank')}
                />
            </RightContainer>
        </Container>
    )
}

export default BannerPROComponent

const Container = styled.section`
    max-width: 1440px;
    max-height: 342px;
    display: flex;
    margin: 44px 0;

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        flex-direction: column;
        max-height: none;
    }

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        max-height: none;
    }
`
const LeftContainer = styled.div`
    width: 100%;
`

const BannerImage = styled.img`
    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        width: 100%;
    }
    @media only screen and (max-width: 499px) {
        width: 100%;
    }
`

const RightContainer = styled.div`
    background-color: ${colors.black.jotaBlack};
    color: ${colors.white.jotaWhite};
    padding: 44px;
    width: 100%;

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        width: inherit;
        padding: 44px 36px;
    }
    @media only screen and (max-width: 499px) {
        width: inherit;
        padding: 44px 16px;
    }
`
const Title = styled.h3`
    ${fonts.jotaSerifHeading03()};
    margin: 18px 0 24px 0;
    max-width: 290px;

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        max-width: inherit;
    }
`
