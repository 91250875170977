import { Live } from '@JOTAJornalismo/jota-design-system'
import { FC } from 'react'
import styled from 'styled-components'
import { SM_PX, MD_PX } from './../header/Header.styled'
import { LiveIH } from '../../interfaces'
/**
 * Header Component
 * @return {React.JSX.Element}
 */

const LiveButtonComponent: FC<{ live: LiveIH }> = ({
    live
}): React.JSX.Element => {
    return (
        <LiveButtonContainer>
            {live && live.link != '' && (
                <Live url={live.link} label={live.title} />
            )}
        </LiveButtonContainer>
    )
}

export default LiveButtonComponent

const LiveButtonContainer = styled.div`
    margin-block: 44px 0;
    margin-inline: 44px;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        margin-inline: 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        margin-inline: 16px;
    }
`
