import { TagDS } from '../../'
import { FC } from 'react'
import styled from 'styled-components'
import { BannerSazonal } from '../../../interfaces'
import { BREAKPOINTS } from '../../../utils/constants/layout'
import { painelAdm } from '../../../utils/fake_api'

import { tokens } from '@JOTAJornalismo/jota-design-system'
const { fonts, colors } = tokens

interface SeasonalBannerI {
    showBanner: boolean
    bannerData: BannerSazonal
}

const SeasonalBannerComponent: FC<SeasonalBannerI> = ({
    showBanner,
    bannerData
}): React.JSX.Element => {
    const selectedOption = [painelAdm[0]]

    return (
        <>
            {showBanner &&
                selectedOption.map((painelAdmItem) => (
                    <BannerSection key={painelAdmItem.id}>
                        <ImageContainer>
                            <BannerImage src={bannerData.images.desktop.url} />
                        </ImageContainer>
                        <CardContainer>
                            <TagDS
                                label={bannerData.hat}
                                size='medium'
                                type='colored'
                            />

                            <CardTitle> {bannerData.title}</CardTitle>
                            <CardText>{bannerData.description}</CardText>
                            <TagDS
                                color='jotaGray'
                                size='medium'
                                type='colored'
                                label={bannerData.authors}
                            />
                        </CardContainer>
                    </BannerSection>
                ))}
        </>
    )
}

export default SeasonalBannerComponent

const BannerSection = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 44px;
    padding-right: 44px;
    background-color: ${colors.transparent.jotaTGrayLighter40};
    margin-block: 44px 44px;

    @media screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        flex-direction: column;
        padding: 0;
        gap: 24px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
        padding: 0;
        gap: 24px;
    }
`

const ImageContainer = styled.div`
    width: 100%;
`

const BannerImage = styled.img`
    aspect-ratio: 16/9;
    width: 100%;
`
const CardContainer = styled.article`
    width: 100%;
    box-sizing: border-box;
    padding-inline: 16px;

    @media screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        flex-direction: column;
        padding-bottom: 24px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        padding-bottom: 24px;
    }
`
const CardTitle = styled.h2`
    margin-block: 6px 18px;
    ${fonts.jotaSerifHeading03};
`

const CardText = styled.p`
    ${fonts.jotaBody};
    color: ${colors.gray.jotaGrayDark};
    margin-block: 0px 12px;
`
