import styled from 'styled-components'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { CardDS } from '../../'
import { FC } from 'react'
import { News } from '../../../interfaces'
import { PATROCINADO } from '../../../utils/constants/home'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import router from 'next/router'

const { spaces } = tokens

export const CONTEUDO_LIBERADO = 'Conteúdo PRO Liberado'.toUpperCase()

/**
 * Middle Column Component
 * @return {React.JSX.Element}
 */
const MiddleColumn: FC<{ destaques: News[] }> = ({
    destaques
}): React.JSX.Element => {
    return (
        <List>
            {destaques?.map((newsItem, index) => (
                <CardDS
                    key={newsItem.id + index}
                    title={
                        newsItem.alternative.title != ''
                            ? newsItem.alternative.title
                            : newsItem.title
                    }
                    titleSearchTerm={''}
                    newsLink={`${checkSpecialOrInfoNews(
                        newsItem.categories,
                        newsItem.slug,
                        newsItem.permalink
                    )}`}
                    size='small'
                    image={newsItem.image}
                    tagLabel={
                        newsItem.alternative.hat != ''
                            ? newsItem.alternative.hat
                            : newsItem.hat
                    }
                    tagDisableHover
                    authorTagLabel={' '}
                    subjectTagLabel={
                        newsItem.inherits_from_PRO
                            ? CONTEUDO_LIBERADO
                            : undefined
                    }
                    subjectTagDisableHover
                    isSponsored={
                        (newsItem.type === PATROCINADO ||
                            newsItem.inherits_from_PRO) ??
                        false
                    }
                    relatedTagLabel={newsItem.category.name}
                    relatedTagDisableHover
                    hasSeparator={index !== destaques.length - 1}
                    multipleAuthors={
                        Array.isArray(newsItem.authors) ? newsItem.authors : []
                    }
                    multipleAuthorsClickEvent={(authorUrl) => {
                        if (authorUrl) {
                            return router.push(`autor/${authorUrl}`)
                        }
                    }}
                />
            ))}
        </List>
    )
}

export default MiddleColumn

const List = styled.ul`
    display: grid;
    gap: ${spaces.jotaSpacing24};
    align-content: start;
    padding: 0;
    margin: 0;
`
