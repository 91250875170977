import styled from 'styled-components'
import { CardDS, ProfileCardDS } from '../../'
import { FC } from 'react'
import { NewsShortMode } from '../../../interfaces'
import { LAYOUT_CONSTANT } from '../../../utils/constants'
import { ARTIGO } from '../../../utils/constants/home'

import { tokens } from '@JOTAJornalismo/jota-design-system'

const { fonts, colors } = tokens

export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

const OpinionAndAnalysisComponent: FC<{ news: NewsShortMode[] }> = ({
    news
}): React.JSX.Element => {
    return (
        <Container>
            <Title>Opinião e Análise</Title>
            <List>
                {news.map((newsItem, index) => (
                    <Item key={newsItem.id}>
                        <ProfileCardDS
                            key={newsItem.id + index}
                            nameSurname={
                                newsItem.type === ARTIGO
                                    ? 'Artigos'
                                    : newsItem.author
                            }
                            description={newsItem.description}
                            pathAvatarImage={newsItem.authorPhoto}
                            type='tertiary'
                            hasAvatar={newsItem.type !== ARTIGO}
                        />
                        <CardDS
                            key={newsItem.id + index * 10}
                            title={newsItem.title}
                            titleSearchTerm={''}
                            text={newsItem.description}
                            newsLink={'https://jota.info'}
                            size='small'
                            tagLabel={newsItem.hat ?? ''}
                            dateTagLabel={newsItem.author}
                        />
                    </Item>
                ))}
            </List>
        </Container>
    )
}

export default OpinionAndAnalysisComponent

const Container = styled.section`
    padding: 44px;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        padding: 44px 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 44px 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04()};
    color: ${colors.black.jotaBlack};
    padding: 0 0 8px 0;
    margin: 0;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
`
const List = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 44px;
    padding: 0;
    margin: 24px 0 0 0;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: ${SM_PX}) {
        grid-template-columns: 1fr;
    }
`
const Item = styled.li`
    list-style-type: none;
    display: grid;
    gap: 16px;
`
