/* eslint-disable camelcase */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CardDS, ButtonDS } from '../../'
import {
    JotaMaterialClose as CloseIcon,
    tokens
} from '@JOTAJornalismo/jota-design-system'
import { axiosJOTA, ProfileAPI } from '../../../../api/api'
import { StoreType } from '../../../../api/types/store'
import { AUTH_CONSTANT } from '../../../utils/constants'
import { getColorByVertical, getIconByString } from '../../../utils/geral'
import JOTAPROLoading from '../../../utils/loading/jotaPRO.loading'
import Image from 'next/image'
import router from 'next/router'
import { verticalI } from '../second-scroll/Vertical.component'

const { colors, fonts } = tokens

type WidgetDataType = {
    userStatus: any
    auth: any
    showMessage: boolean
    isHovering: boolean
    setShowMessage: any
    hoverRef: any
    nl: NewsletterI[]
}

type TypeData = 'poder' | 'tributos' | 'all' | 'saúde'

type NewsletterI = {
    title: string
    link: string
    type: TypeData
    date: Date
    hat: string
    icon: string
    verticalSlug: string
    newsletterSlug: string
}

type ProContentBO = {
    authors: any[]
    icon: string
    id: number
    send_time: string
    slug: string
    title: string
    category: {
        icon: string
        id: number
        name: string
        vertical: {
            name: string
            id: number
        }
    }
}

const useHover = (ref: React.MutableRefObject<HTMLElement | null>) => {
    const [isHovering, setIsHovering] = React.useState(false)

    if (ref?.current) {
        ref.current.addEventListener('mouseover', () => setIsHovering(true))
        ref.current.addEventListener('mouseout', () => setIsHovering(false))
    }

    return isHovering
}

export type verticalType = 'poder' | 'tributos' | 'saude'

export const makeProURL = (
    vertical: verticalType,
    newsletter: string,
    slug: string
) => {
    let newsURL = newsletter

    if (newsletter === 'direto-da-anvisa' || newsletter === 'direto-da-ans') {
        newsURL = 'direto-da-anvisa-ans'
    }

    return `/${vertical}/${newsURL}/${slug}`
}

export const generateSlug = (title: string): string => {
    return title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
}

const showWidgetJOTAPRO = (widgetData: WidgetDataType) => {
    const {
        userStatus,
        auth,
        showMessage,
        isHovering,
        setShowMessage,
        hoverRef,
        nl
    } = widgetData

    if (
        auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED &&
        auth.isProUser
    ) {
        const blockVisibilityAccordingToUserVertical = (vertical: string) => {
            const userBO: any = auth.user?.getUserBO()
            if (userBO?.profile) {
                if (vertical === 'poder' && userBO.profile.has_poder) {
                    return false
                } else if (
                    vertical === 'tributos' &&
                    userBO.profile.has_tributos
                ) {
                    return false
                } else if (vertical === 'saúde' && userBO.profile.has_saude) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
        return (
            <CardsContainer userStatus={userStatus}>
                {!showMessage && (
                    <MessageContainer>
                        <Message>
                            Como assinante
                            <Pro> JOTA PRO, </Pro>
                            você tem acesso a conteúdos exclusivos e alinhados
                            aos seus interesses.
                        </Message>
                        <div style={{ width: 24, height: 24 }} ref={hoverRef}>
                            {isHovering ? (
                                <CloseIcon
                                    fill={colors.black.jotaBlack}
                                    width='24px'
                                    height='24px'
                                    cursor='pointer'
                                    onClick={() => setShowMessage(false)}
                                />
                            ) : (
                                <CloseIcon
                                    fill={colors.transparent.jotaTBlack24}
                                    width='24px'
                                    height='24px'
                                    cursor='pointer'
                                    onClick={() => setShowMessage(false)}
                                />
                            )}
                        </div>
                    </MessageContainer>
                )}
                <List>
                    {nl.length > 0 ? (
                        nl.map((newsItem: any, index: any) => {
                            return (
                                <CardDS
                                    key={newsItem.link}
                                    title={newsItem.title}
                                    titleSearchTerm={''}
                                    newsLink={makeProURL(
                                        newsItem.verticalSlug as verticalType,
                                        newsItem.newsletterSlug,
                                        newsItem.link
                                    )}
                                    size='small'
                                    tagLabel={newsItem.hat}
                                    tagColor={getColorByVertical(
                                        newsItem.type as verticalI
                                    )}
                                    tagDisableHover
                                    authorTagLabel={`${newsItem.date.getDate()}/${
                                        newsItem.date.getMonth() + 1
                                    }/${newsItem.date.getFullYear()}`}
                                    relatedTagLabel={`${String(
                                        newsItem.date.getHours()
                                    ).padStart(2, '0')}:${String(
                                        newsItem.date.getMinutes()
                                    ).padStart(2, '0')}`}
                                    type='bleeding'
                                    hasSeparator={index !== nl.length - 1}
                                    TagLeftIcon={getIconByString(newsItem.icon)}
                                    isContentPro={
                                        auth?.authState ===
                                        AUTH_CONSTANT.AUTH_STATE.USER_LOGGED
                                            ? blockVisibilityAccordingToUserVertical(
                                                  newsItem.type.toLowerCase()
                                              )
                                            : true
                                    }
                                    isUserPro={
                                        auth?.authState ===
                                        AUTH_CONSTANT.AUTH_STATE.USER_LOGGED
                                            ? !blockVisibilityAccordingToUserVertical(
                                                  newsItem.type.toLowerCase()
                                              )
                                            : false
                                    }
                                    relatedTagDisableHover
                                    authorTagDisableHover
                                    subjectTagLabel={newsItem.type}
                                    subjectTagColor={getColorByVertical(
                                        (newsItem.type.charAt(0).toUpperCase() +
                                            newsItem.type.slice(1)) as verticalI
                                    )}
                                />
                            )
                        })
                    ) : (
                        <JOTAPROLoading />
                    )}
                </List>
                <ButtonDS
                    label='Mais conteúdos PRO'
                    color='black'
                    size='small'
                    onClick={() => {
                        router.push('/pro')
                    }}
                    type='filled'
                    LastIcon={'jotaMaterialArrow'}
                    fillIcone={'jotaWhite'}
                    insideType='right'
                />
            </CardsContainer>
        )
    } else if (
        auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED &&
        !auth?.isProUser
    ) {
        return (
            <CardsContainer userStatus={!AUTH_CONSTANT.AUTH_STATE.USER_LOGGED}>
                <Cta data-test='CTA-info-users'>
                    <a
                        href={`${process.env.NEXT_PUBLIC_LP_PRO}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <ButtonDS
                            id='widget-seja-pro'
                            label='conheça o jota pro'
                            size='medium'
                            color='black'
                            type='filled'
                            width='100%'
                        />
                    </a>
                    <Text>
                        Seja assinante <Pro>JOTA PRO</Pro> e tenha acesso a
                        conteúdos exclusivos e moldados aos seus interesses.
                    </Text>
                </Cta>
            </CardsContainer>
        )
    } else {
        return (
            <CardsContainer userStatus={!AUTH_CONSTANT.AUTH_STATE.USER_LOGGED}>
                <Cta data-test='CTA-unlogged-users'>
                    <a
                        href={`${process.env.NEXT_PUBLIC_JOTAINFO}/pro`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <ButtonDS
                            id='widget-seja-pro'
                            label='conheça o jota pro'
                            size='medium'
                            color='black'
                            type='filled'
                            width='100%'
                        />
                    </a>
                    <Text>
                        Seja assinante <Pro>JOTA PRO</Pro> e tenha acesso a
                        conteúdos exclusivos e moldados aos seus interesses.
                    </Text>
                    <Text data-test='make-login'>
                        Já é PRO? &nbsp;
                        <Link href='/login'>Faça login</Link>
                    </Text>
                </Cta>
            </CardsContainer>
        )
    }
}

/**
 * Meu JOTA Component - USUÁRIO LOGADO
 * @return {React.JSX.Element}
 */
const JotaPROComponent = ({ userStatus }: any): React.JSX.Element => {
    const [showMessage, setShowMessage] = useState<boolean>(true)
    const hoverRef = useRef<HTMLElement | null>(
        null
    ) as MutableRefObject<HTMLDivElement>
    const isHovering = useHover(hoverRef)
    const { auth } = useSelector((state: StoreType) => state)
    const [nl, setNl] = useState<NewsletterI[]>([])
    const userBO: any = auth.user?.getUserBO()

    const hasPRO = (profile: {
        has_poder: boolean
        has_tributos: boolean
        has_saude: boolean
    }) => {
        if (profile?.has_poder || profile?.has_tributos || profile?.has_saude) {
            return true
        }
        return false
    }

    useEffect(() => {
        /**
         * Get Newsletter from Backoffice API
         */
        const getNewsletter = async () => {
            const token = auth.user?.getIDToken()
            if (token) {
                void axiosJOTA
                    .get(ProfileAPI.recentNewsletter(), {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then((nwData) => {
                        const data = nwData.data.results
                        const nlTemp: NewsletterI[] = data.map(
                            (item: ProContentBO): NewsletterI => {
                                const typeTemp: 'poder' | 'tributos' | 'all' =
                                    item?.category?.vertical?.name?.toLowerCase() as
                                        | 'poder'
                                        | 'tributos'
                                        | 'all'

                                return {
                                    date: new Date(item.send_time),
                                    hat: item.category.name,
                                    link: item.slug,
                                    title: item.title,
                                    icon: item.category.icon,
                                    type: typeTemp,
                                    verticalSlug: generateSlug(
                                        item.category.vertical.name
                                    ),
                                    newsletterSlug: generateSlug(
                                        item.category.name
                                    )
                                }
                            }
                        )
                        setNl(nlTemp)
                    })
            }
        }
        if (auth.user?.getIDToken() && hasPRO(userBO?.profile)) {
            void getNewsletter()
        }
    }, [auth.user, userBO?.profile])

    return (
        <Container userStatus={userStatus} data-testid='news-pro-container'>
            <Title
                href={
                    auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED
                        ? '/pro'
                        : '/login'
                }
            >
                <Image
                    src='/images/logo-jota-pro.svg'
                    alt='jota-pro'
                    width={132}
                    height={28}
                />
            </Title>
            {showWidgetJOTAPRO({
                userStatus,
                auth,
                showMessage,
                isHovering,
                setShowMessage,
                hoverRef,
                nl
            })}
        </Container>
    )
}

export default JotaPROComponent

const Container = styled.div<{ userStatus: any }>`
    display: grid;
    grid-auto-rows: min-content auto;
    border-radius: 4px;
    background-color: ${colors.transparent.jotaTGrayLighter40};

    ${(props) =>
        props.userStatus === 'logado' &&
        `align-content: start;
        `};
`

const Title = styled.a`
    ${fonts.jotaHeading05()};
    padding: 16px 16px 8px 16px;
    margin: 0;
    text-decoration: none;
`

const CardsContainer = styled.div<{ userStatus: any }>`
    display: grid;

    & > div:last-child {
        padding: 16px;
    }

    & > button {
        margin: 0px 16px 16px 16px;
    }

    ${(props) =>
        props.userStatus === !AUTH_CONSTANT.AUTH_STATE.USER_LOGGED &&
        `background: center / contain no-repeat url('/images/background-blur.jpeg');
        `};

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        background-image: none;
    }

    @media only screen and (max-width: 499px) {
        background-image: none;
    }
`

const MessageContainer = styled.div`
    display: grid;
    gap: 8px;
    padding: 16px;
    grid-template-columns: auto 24px;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLighter40};
    border-top: 1px solid ${colors.transparent.jotaTGrayLighter40};
    background-color: ${colors.transparent.jotaTGrayLighter40};
`

const Message = styled.p`
    ${fonts.jotaBodySmall()};
    color: ${colors.gray.jotaGrayDarker};
    margin: 0;
`
const Pro = styled.span`
    font-weight: 700;
`

const List = styled.ul`
    display: grid;
    padding: 0;
    margin: 0;
`

const Cta = styled.div`
    padding: 16px;
    border-radius: 4px;
    background-color: ${colors.white.jotaWhite};
    margin: 16px;
    max-width: 300px;
    place-self: center center;
`
const Text = styled.p`
    ${fonts.jotaBodySmall()}
    color: ${colors.gray.jotaGrayDarker};
    margin-bottom: 0;
`

const Link = styled.a`
    color: ${colors.orange.jotaOrange};
    font-weight: 700;
    text-decoration: underline;
`
